import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import AsyncLoadable from '@/utils/AsyncLoadable'
import 'animate.css'
import './style/base.less'
import './style/App.less'

const DefaultLayout = AsyncLoadable(() => import(/* webpackChunkName: 'default' */ './containers'))

const View404 = AsyncLoadable(() => import(/* webpackChunkName: '404' */ './views/Others/404'))
const View500 = AsyncLoadable(() => import(/* webpackChunkName: '500' */ './views/Others/500'))
const View401 = AsyncLoadable(() => import(/* webpackChunkName: 'login' */ './views/Others/401'))
const Login = AsyncLoadable(() => import(/* webpackChunkName: 'login' */ './views/Login'))
// const OTP = AsyncLoadable(() => import(/* webpackChunkName: 'otp' */ './views/OTP'))
const App1 = () => {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path='/' exact render={() => <Redirect to='/index' />} />
                    <Route path='/500' component={View500} />
                    <Route path='/login' component={Login} />
                    {/* <Route path='/otp' component={OTP} /> */}
                    <Route path='/404' component={View404} />
                    <Route path='/401' component={View401} />
                    <Route component={DefaultLayout} />
                </Switch>
            </Router>
        </Fragment>
    )
}
class App extends React.Component {
    render() {
        // if(this.props.history.location.pathname === "/login")
        //   window.history.pushState(null, null, "/BillingStatement");
        // window.history.pushState({page: 0}, "", window.location.href);
        // window.onpopstate = function(event){
        //   window.history.go(0);
        // };
        // window.addEventListener('popstate', function(event) {
        //   console.log(event);
        //   // window.history.pushState(null, null, '/BillingStatement');
        // });
        // window.history.pushState(null, null, document.URL);
        // window.addEventListener("popstate", function () {
        //   window.history.pushState(null, null, document.URL);
        // });
        return <App1 />
    }
}

export default withRouter(App)
