// import React, {BrowserRouter} from "react";
// import ReactDOM from "react-dom";
// import App from "./App";

// ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById("root"));
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import App from './App'
import './index.css'

const rootElement = document.getElementById('root')
const parenetRootElement = rootElement.parentElement
// if (isMobile) {
//     parenetRootElement.classList.add('mobile')
// }
ReactDOM.render(
    <BrowserRouter>
        <Route path='/' component={App} />
    </BrowserRouter>,
    rootElement
)
